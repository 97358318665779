/* eslint-disable @typescript-eslint/no-unused-vars */
import { TableFullRowSelect } from '@spidertracks/components';
import { Button, ConfigProvider, notification, PageHeader, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  getDateTimeFormat,
  getDistanceUnit,
  getTimezone,
  getUserData
} from 'redux/selectors/userData';
import { getInstance } from '../../common/api/spidertracks-sdk';
import {
  historyTableDeleteError,
  historyTableDeleteError404,
  historyTableDeleteErrorAuth,
  replayError
} from '../../helpers';
import { formatSecondsToHoursDecimal, initializeDuration } from '../../helpers/formatTime';
import { useFeatureFlag, useHistoryTableData } from '../../hooks';
import { getRowClassName } from '../common';
import {
  DateFilter,
  FilterIconFunnel,
  getFilterDropdown,
  getRanges,
  getTaggedLabel
} from '../common/filters';
import { DELETED } from '../Flying/AircraftList/constants';
import FlightPlayerModal from './FlightPlayerModal';
import { HistoryTableHeader } from './HistoryTableHeader';
import { historyReducer } from './historyReducer';
import ReplayColumnCell from './ReplayColumnCell';
import ReplayColumnHeader from './ReplayColumnHeader';
import { useHistory } from 'react-router-dom';
import { useHistoryParams } from './useHistoryParams';
import moment from 'moment';
import { getSpinner } from '../../util';
import { abbreviateDistanceUnit } from '../Flying/Map/utils/helper';
import {
  resetFilterConfig,
  setFilterConfig,
  setTrackStatus
} from '../../redux/slice/historyTracks';
import {
  Archived,
  Available,
  AwaitingOffload,
  Incomplete,
  NotAvailable,
  NotSupported
} from './icons';
import { FlightStatus } from '../../constants';
import { buildHistoryUrl } from './buildHistoryUrl';

const RESULTS_PER_PAGE = 30;
const DEFAULT_DOWNLOAD_LIMIT = 200;
const HIGH_DOWNLOAD_LIMIT = 1000;

const TooltipComponentWrapper = props => (
  <Tooltip placement="bottom" trigger="hover" title={props.tooltipText}>
    <div>{props.child}</div>
  </Tooltip>
);

const sortAircraftFilterList = (a, b, aircraftFilteredValues = []) => {
  const aRegistration = a.registration.trim().toLowerCase(),
    bRegistration = b.registration.trim().toLowerCase(),
    includesA = aircraftFilteredValues.includes(a.value),
    includesB = aircraftFilteredValues.includes(b.value);

  if (a.deleted && b.deleted) return aRegistration > bRegistration ? 1 : -1;
  if (a.deleted) return 1;
  if (b.deleted) return -1;

  if (includesA && includesB) return aRegistration > bRegistration ? 1 : -1;
  if (includesA) return -1;
  if (includesB) return 1;

  return aRegistration > bRegistration ? 1 : -1;
};

export const mapAircraftsToAircraftFilterList = aircrafts => {
  return aircrafts.map(val => {
    let registration = val.registration;
    let deleted = val.status === 'DELETED';

    return {
      deleted,
      registration,
      label: deleted ? getTaggedLabel(registration, DELETED) : registration,
      value: val.id,
      key: val.id
    };
  });
};

export const HistoryContainer = ({
  dateFormat = 'DD/MM/YYYY HH:mm:ss',
  timezone = 'Pacific/Auckland',
  distanceUnit = 'Miles',
  userData
}) => {
  const history = useHistory();
  const tableContainer = useRef(null);
  const tableScrollHeight = useMemo(
    () => tableContainer?.current?.getBoundingClientRect().height - 300,
    [tableContainer.current]
  );

  const initialState = {
    totalDistance: 0,
    totalDuration: initializeDuration(),
    tableData: [],
    aircraftFilterList: [],
    selectedRowKeys: []
  };

  const { orgs = [] } = userData;
  const userOrgIds = orgs.reduce((acc, o) => acc.concat(o.org.id), []);

  const SpidertracksSDK = getInstance();
  const is3DFlightReplayColumnEnabled = useFeatureFlag('3d-flight-replay-column', userOrgIds);
  //const { is3DFlightReplayColumnEnabled } = useFlags();
  const isFlightReportEnhancementsEnabled = useFeatureFlag(
    'flight-report-enhancements',
    userOrgIds
  );
  const isEngineTotalHrsColumnEnabled = useFeatureFlag(
    'history-show-3rd-party-engine-totals',
    userOrgIds
  );
  const isEngineTotalStartsColumnEnabled = useFeatureFlag(
    'history-show-3rd-party-engine-starts',
    userOrgIds
  );

  const flags = {
    useNewFlightDataService: isFlightReportEnhancementsEnabled
  };
  const [
    { totalDistance, totalDuration, tableData, selectedRowKeys, aircraftFilterList, viewingTrack },
    dispatch
  ] = useReducer(historyReducer, initialState);
  const reduxDispatch = useDispatch();

  const dispatchAll = actions =>
    actions.forEach(action => {
      let type = action.type;
      let payload = action.payload;
      dispatch({ type, payload });
    });

  const clearSelection = useCallback(
    () =>
      dispatchAll([
        { type: 'selectedRowKeys', payload: [] },
        { type: 'totalDistance', payload: 0 },
        { type: 'totalDuration', payload: initializeDuration() }
      ]),
    []
  );

  const {
    aircraftFilteredValues = [],
    distanceFilteredValues,
    durationFilteredValues,
    endTime,
    page = 1,
    sosFilteredValues = [],
    startTime,
    updateParams
  } = useHistoryParams();

  const search = new URLSearchParams(history.location.search);
  search.set('resultsPerPage', RESULTS_PER_PAGE);
  const [
    fetchHistoryTracks,
    trackData,
    meta,
    loadingTrackHistory,
    hasErrored
  ] = useHistoryTableData(
    search,
    {
      dateFormat,
      timezone,
      distanceUnit,
      flags
    },
    false
  );
  const didMount = useRef(false);
  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    search.set('resultsPerPage', RESULTS_PER_PAGE);
    search.set('includeFlightLogs', 'true');
    if (!didMount.current && !endTime && !startTime) {
      // This is the first render. Set a default time period in the URL.
      updateParams({
        endTime: moment(),
        startTime: moment().subtract(3, 'months')
      });
      didMount.current = true;
      return;
    }

    fetchHistoryTracks(search);
  }, [history.location.search]);

  const initialiseHistoryTracksReduxStore = useCallback(trackData => {
    if (!trackData) {
      return;
    }
    const trackStatus = trackData.map(track => ({
      id: track.id,
      '3dReplayAvailable': track.replayStatus && track.replayStatus.toLowerCase() === 'available'
    }));
    reduxDispatch(setTrackStatus({ trackStatus, init: true }));

    const params = new URLSearchParams(history.location.search);

    const aircraftIds = params.get('aircraftIds');
    const aircraft = aircraftIds ? aircraftIds.split(',') : undefined;

    const sosStatus = params.get('sos');
    const sos = sosStatus
      ? sosStatus.split(',').map(s => s.charAt(0).toUpperCase() + s.slice(1))
      : undefined;

    const spiderTimeGreaterThanOneMinute = params.get('duration') === 'true' || undefined;
    const aircraftMoved = params.get('distance') === 'true' || undefined;

    const filterConfig = {
      aircraft,
      spiderTimeGreaterThanOneMinute,
      aircraftMoved,
      sos
    };
    reduxDispatch(setFilterConfig(filterConfig));
  });

  useEffect(() => {
    if (meta) {
      dispatch({ type: 'tableData', payload: trackData });
      initialiseHistoryTracksReduxStore(trackData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, trackData]);

  const getAircraftFilterList = async () => {
    const aircrafts = await getInstance().getAircraft();
    return mapAircraftsToAircraftFilterList(aircrafts);
  };

  useEffect(() => {
    getAircraftFilterList().then(aircraftFilterList =>
      dispatch({ type: 'aircraftFilterList', payload: aircraftFilterList })
    );
  }, []);

  const sortedAircraftFilterList = useMemo(() => {
    return (
      aircraftFilterList &&
      [...aircraftFilterList].sort((a, b) => sortAircraftFilterList(a, b, aircraftFilteredValues))
    );
  }, [aircraftFilterList, aircraftFilteredValues]);

  const deleteTracks = async () => {
    try {
      await SpidertracksSDK.deleteTracks(selectedRowKeys);
    } catch (e) {
      switch (e.httpResponse?.status) {
        case 404:
          historyTableDeleteError404();
          break;
        case 403:
          historyTableDeleteErrorAuth();
          break;
        default:
          historyTableDeleteError();
      }
    } finally {
      const search = new URLSearchParams(history.location.search);
      search.set('resultsPerPage', RESULTS_PER_PAGE);
      dispatch({ type: 'selectedRowKeys', payload: [] });
      fetchHistoryTracks(search);
      if (
        trackData.length === selectedRowKeys.length &&
        page === Math.ceil(meta.total / RESULTS_PER_PAGE)
      ) {
        updateParams({
          page: page - 1 || 1
        });
      }
    }
  };

  const downloadFullCSVSummary = async () => {
    const downloadNotificationKey = 'download-notification';
    notification.open({
      placement: 'topRight',
      message: 'Downloading track summary',
      description: 'Your download is being processed, it will start shortly.',
      key: downloadNotificationKey,
      duration: 0,
      icon: getSpinner()
    });

    const params = new URLSearchParams();
    const limit = meta.total < 1000 ? meta.total : 1000;

    // startTime and endTimes can be undefined and momentjs will convert this to the current time
    if (startTime) params.set('startTimeEpochMillis', moment(startTime).unix() * 1000);
    if (endTime) params.set('endTimeEpochMillis', moment(endTime).unix() * 1000);

    if (aircraftFilteredValues !== undefined && aircraftFilteredValues.length !== 0)
      params.set('aircraftIds', aircraftFilteredValues.join(','));

    if (sosFilteredValues !== undefined && sosFilteredValues.length !== 0)
      params.set('sosStatus', sosFilteredValues.join(','));

    params.set('limit', limit);

    // durationFilteredValues = true means we are showing zeroMinFlights
    if (durationFilteredValues !== undefined)
      params.set('excludeZeroMinTracks', durationFilteredValues);

    // distanceFilteredValues = true means we are showing zeroDistanceFlights
    if (distanceFilteredValues !== undefined)
      params.set('excludeZeroDistanceTracks', distanceFilteredValues);

    await SpidertracksSDK.getHistoryTableTracksCSV(params);

    notification.close(downloadNotificationKey);
  };

  const handleTableChange = (pagination, { aircraft, distance, formattedTrackDuration, sos }) => {
    updateParams({
      aircraft,
      distanceFilteredValues: distance,
      durationFilteredValues: formattedTrackDuration,
      sosFilteredValues: sos,
      page: pagination.current
    });
  };

  const clearAllFilters = () => {
    dispatch({ type: 'selectedRowKeys', payload: [] });
    updateParams({
      distanceFilteredValues: false,
      durationFilteredValues: false,
      aircraftFilteredValues: [],
      sosFilteredValues: [],
      startTime: null,
      endTime: null,
      page: 1
    });
    reduxDispatch(resetFilterConfig());
  };

  const onChange = selectedRowKeys => {
    dispatch({ type: 'selectedRowKeys', payload: selectedRowKeys });
  };

  const onSelect = (record, selected) => {
    if (selected) {
      dispatchAll([
        { type: 'totalDuration', payload: totalDuration.add(record.unformattedTrackDuration) },
        { type: 'totalDistance', payload: totalDistance + record.rawDistance }
      ]);
    } else {
      dispatchAll([
        { type: 'totalDuration', payload: totalDuration.subtract(record.unformattedTrackDuration) },
        { type: 'totalDistance', payload: totalDistance - record.rawDistance }
      ]);
    }
  };

  const onSelectAll = (selected, selectedRows, changeRows) => {
    const [totalPageDuration, totalPageDistance] = changeRows.reduce(
      (acc, val) => [acc[0].add(val.unformattedTrackDuration), (acc[1] += val.rawDistance)],
      [initializeDuration(), 0]
    );
    if (selected) {
      dispatchAll([
        { type: 'totalDuration', payload: totalDuration.add(totalPageDuration) },
        { type: 'totalDistance', payload: totalDistance + totalPageDistance }
      ]);
    }

    if (!selected) {
      clearSelection();
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange,
    onSelect,
    onSelectAll
  };

  const downloadLimit = useFeatureFlag('high-download-limit', userOrgIds)
    ? HIGH_DOWNLOAD_LIMIT
    : DEFAULT_DOWNLOAD_LIMIT;

  const aircraftFilterDropdownProps = getFilterDropdown({
    onFilter: aircraftFilteredValues => updateParams({ aircraftFilteredValues, page: 1 }),
    displayName: 'aircraft',
    style: {
      width: 285
    },
    updateOnConfirm: true,
    showSearch: true
  });

  const durationFilterDropdownProps = getFilterDropdown({
    onFilter: durationFilteredValues => updateParams({ durationFilteredValues, page: 1 }),
    displayName: 'duration',
    updateOnConfirm: true
  });

  const distanceFilterDropdownProps = getFilterDropdown({
    onFilter: distanceFilteredValues => updateParams({ distanceFilteredValues, page: 1 }),
    displayName: 'distance',
    updateOnConfirm: true
  });

  const sosFilterDropdownProps = getFilterDropdown({
    onFilter: sosFilteredValues => updateParams({ sosFilteredValues, page: 1 }),
    displayName: 'SOS',
    updateOnConfirm: true
  });

  const columns = [
    {
      title: 'AIRCRAFT',
      dataIndex: 'aircraft',
      key: 'aircraft',
      filters: sortedAircraftFilterList,
      ...aircraftFilterDropdownProps(),
      filteredValue: aircraftFilteredValues,
      filterMultiple: true,
      width: '12.5rem'
    },
    {
      title: 'FLIGHT ID',
      dataIndex: 'flightId',
      width: '6rem'
    },
    {
      title: 'START TIME',
      dataIndex: 'startTime',
      width: '13.3rem'
    },
    {
      title: 'SPIDER TIME (hrs)',
      dataIndex: 'durationDecimalHours',
      key: 'durationDecimalHours',
      filters: [{ label: 'Greater than 1 min', value: true }],
      ...durationFilterDropdownProps(),
      filterIcon: FilterIconFunnel,
      filteredValue: durationFilteredValues ? [true] : [],
      width: '7.4rem'
    },
    {
      title: `DISTANCE (${abbreviateDistanceUnit(distanceUnit)})`,
      dataIndex: 'distance',
      key: 'distance',
      filters: [{ label: 'Aircraft moved', value: true }],
      ...distanceFilterDropdownProps(),
      filterIcon: FilterIconFunnel,
      filteredValue: distanceFilteredValues ? [true] : [],
      width: '8rem'
    },
    {
      title: 'SOS',
      dataIndex: 'sosStatus',
      key: 'sos',
      filters: [
        { label: 'Active', value: 'active' },
        { label: 'Closed', value: 'closed' }
      ],
      filteredValue: sosFilteredValues,
      ...sosFilterDropdownProps(),
      filterIcon: FilterIconFunnel,
      width: '5rem'
    }
  ];

  if (is3DFlightReplayColumnEnabled) {
    columns.push({
      title: <ReplayColumnHeader />,
      dataIndex: 'replayStatus',
      width: '6.5rem',
      // eslint-disable-next-line react/display-name
      render: (replayStatus, rowData) => {
        return (
          <ReplayColumnCell
            {...{
              replayStatus,
              rowData,
              openPlayer: () => {
                const { aircraft, aircraftType, aircraftModel, startTime } = rowData;
                if (aircraft === 'Registration unavailable' || aircraftType === null) {
                  return replayError();
                }
                dispatch({
                  type: 'viewTrack',
                  payload: {
                    trackId: rowData.id,
                    stId: rowData.key,
                    orgId: rowData.orgId,
                    aircraft,
                    aircraftType,
                    aircraftModel,
                    startTime,
                    flags: {
                      useNewFlightDataService: isFlightReportEnhancementsEnabled
                    }
                  }
                });
              }
            }}
          />
        );
      }
    });
  } else {
    columns.push({
      title: 'INSIGHTS DATA',
      dataIndex: 'replayStatus',
      width: '6rem',
      align: 'center',
      render: (replayStatus, rowData) => {
        const toTooltip = {
          [FlightStatus.NOT_AVAILABLE]: 'Not available',
          [FlightStatus.NOT_AVAILABLE.replace('Available', 'available')]: 'Not available',
          [FlightStatus.AWAITING_OFFLOAD]: 'Awaiting upload', // [sic]
          [FlightStatus.AVAILABLE]: 'Available',
          [FlightStatus.INCOMPLETE]: 'Data upload incomplete',
          [FlightStatus.NOT_SUPPORTED]: 'Spider X required',
          [FlightStatus.ARCHIVED]: 'Archived'
        };

        const toIcon = {
          [FlightStatus.NOT_AVAILABLE]: <NotAvailable />,
          [FlightStatus.NOT_AVAILABLE.replace('Available', 'available')]: <NotAvailable />,
          [FlightStatus.AWAITING_OFFLOAD]: <AwaitingOffload />,
          [FlightStatus.AVAILABLE]: <Available />,
          [FlightStatus.INCOMPLETE]: <Incomplete />,
          [FlightStatus.NOT_SUPPORTED]: <NotSupported />,
          [FlightStatus.ARCHIVED]: <Archived />
        };

        if (replayStatus === 'Loading' && !hasErrored) {
          return getSpinner();
        } else if (hasErrored) {
          replayStatus = FlightStatus.NOT_AVAILABLE;
        } else if (rowData.spiderType !== 'SX') {
          replayStatus = FlightStatus.NOT_SUPPORTED;
        }
        return TooltipComponentWrapper({
          tooltipText: toTooltip[replayStatus],
          child: toIcon[replayStatus]
        });
      }
    });
  }

  columns.push({
    title: 'ENGINE ON',
    dataIndex: 'flightLog.engineOnTime',
    width: '4.7rem'
  });
  columns.push({
    title: 'BLOCKS OFF',
    dataIndex: 'flightLog.firstBlocksOffTime',
    width: '5rem'
  });
  columns.push({
    title: 'TAKE OFF',
    dataIndex: 'flightLog.firstTakeoffTime',
    width: '4rem'
  });
  columns.push({
    title: 'LANDING',
    dataIndex: 'flightLog.lastLandingTime',
    width: '5rem'
  });
  columns.push({
    title: 'BLOCKS ON',
    dataIndex: 'flightLog.lastBlocksOnTime',
    width: '5rem'
  });
  columns.push({
    title: 'ENGINE OFF',
    dataIndex: 'flightLog.engineOffTime',
    width: '4.8rem'
  });
  columns.push({
    title: 'STARTS',
    dataIndex: 'flightLog.totalEngineStarts',
    width: '4.5rem'
  });
  if (isEngineTotalStartsColumnEnabled) {
    columns.push({
      title: 'STARTS TOTAL',
      dataIndex: 'flightLog.total3rdPartyEngineStarts',
      width: '5rem'
    });
  }
  columns.push({
    title: 'LANDINGS',
    dataIndex: 'flightLog.totalLanding',
    width: '5.7rem'
  });
  columns.push({
    title: 'ENGINE TIME (hrs)',
    dataIndex: 'flightLog.engineTime',
    width: '5.5rem'
  });
  if (isEngineTotalHrsColumnEnabled) {
    columns.push({
      title: 'ENGINE TOTAL (hrs)',
      dataIndex: 'flightLog.total3rdPartyEngineRuntimeSeconds',
      width: '5rem'
    });
  }
  columns.push({
    title: 'BLOCKS TIME (hrs)',
    dataIndex: 'flightLog.blockTime',
    width: '5.5rem'
  });
  columns.push({
    title: 'AIR TIME (hrs)',
    dataIndex: 'flightLog.airTime',
    width: '5rem'
  });

  const onRow = ({ key: trackId }) => ({
    onClick: e => {
      e.preventDefault();
      const isReplayAvailable =
        trackData.find(track => track.key === trackId).replayStatus === 'Available';
      const url = buildHistoryUrl(trackId, {
        isFlightReportEnhancementsEnabled,
        isReplayAvailable
      });
      history.push(url);
    }
  });

  const userOrgsRoleObj = orgs.reduce((acc, org) => ({ ...acc, [org.org.id]: org.role }), {});
  const selectedTracksInfo = trackData
    .filter(track => selectedRowKeys.includes(track.key))
    .map(track => {
      return { ...track, orgUserRole: userOrgsRoleObj[track.orgId] };
    });

  const totalDurationSeconds = totalDuration.asSeconds();

  const totalDurationFormatted = useMemo(() => {
    return `${formatSecondsToHoursDecimal(totalDurationSeconds, 2)} hrs`;
  }, [totalDuration, totalDurationSeconds]);

  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

  const totalWidth = columns.reduce(
    (sum, column) => sum + parseFloat(column.width) * rootFontSize,
    0
  );

  return (
    <React.Fragment>
      <div
        ref={tableContainer}
        className="px-5 py-1 pb-4"
        style={{ overflowY: 'scroll', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Flight History"
          subTitle="View and manage flight history"
        />
        <div className="pb-3">
          <DateFilter
            ranges={getRanges()}
            calendarFieldsValues={[
              startTime === null ? undefined : startTime,
              endTime === null ? undefined : endTime
            ]}
            timezone={timezone}
            dateFormat={'YYYY-MM-DD'}
            handleChange={dates => {
              if (dates.length === 0) {
                updateParams({ startTime: null, endTime: null, page: 1 });
                return;
              }
              updateParams({ startTime: dates[0], endTime: dates[1], page: 1 });
            }}
          />
          <Button size="large" style={{ margin: '0 0 0 20px' }} onClick={clearAllFilters}>
            Clear Filters
          </Button>
        </div>
        <ConfigProvider getPopupContainer={() => document.body}>
          <HistoryTableHeader
            selectedTracksInfo={selectedTracksInfo}
            selectedRowKeys={selectedRowKeys}
            deleteTracks={deleteTracks}
            history={history}
            totalDuration={totalDurationFormatted}
            totalDistance={totalDistance}
            distanceUnit={distanceUnit}
            downloadLimit={downloadLimit}
            totalTrackCount={meta ? meta.total : 0}
            downloadFullSummary={downloadFullCSVSummary}
            isFlightReportEnhancementEnabled={isFlightReportEnhancementsEnabled}
          />
          <div style={{ flex: '1' }}>
            <TableFullRowSelect
              bordered
              size="middle"
              dataSource={tableData}
              rowClassName={getRowClassName}
              columns={columns}
              loading={loadingTrackHistory}
              pagination={{
                current: page,
                pageSize: RESULTS_PER_PAGE,
                total: meta ? meta.total : 0
              }}
              onChange={handleTableChange}
              rowSelection={rowSelection}
              className="history-table"
              onRow={onRow}
              scroll={{ y: tableScrollHeight, x: totalWidth }}
            />
          </div>
        </ConfigProvider>
        <FlightPlayerModal
          {...{
            viewingTrack,
            onCancel: () => dispatch({ type: 'viewTrack', payload: undefined })
          }}
        ></FlightPlayerModal>
      </div>
    </React.Fragment>
  );
};

HistoryContainer.propTypes = {
  dateFormat: PropTypes.string,
  timezone: PropTypes.string,
  distanceUnit: PropTypes.string,
  userData: PropTypes.object
};

const mapStateToProps = state => ({
  dateFormat: getDateTimeFormat(state),
  timezone: getTimezone(state),
  distanceUnit: getDistanceUnit(state),
  userData: getUserData(state)
});

export default connect(mapStateToProps)(HistoryContainer);
