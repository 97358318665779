import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getInstance } from '../../../../common/api/spidertracks-sdk';
import { FlightStatus } from '../../../../constants';
import { useFeatureFlag, useFlightStatus } from '../../../../hooks';
import { getSelectedTrack } from '../../../../redux/selectors/aircraftData';
import { getUserData, isOrgRoleOwnerOrAdmin } from '../../../../redux/selectors/userData';

let tracks = [];

export const useFlightReport = () => {
  const [isSpiderX, setIsSpiderX] = useState(false);
  const [trackMetadata, setTrackMetadata] = useState({});
  const selectedTrack = useSelector(getSelectedTrack);
  const userData = useSelector(getUserData);
  const userOrgIds = userData.orgs.reduce((acc, o) => acc.concat(o.org.id), []);
  const sdkInstance = getInstance();

  const adminUserOrgIds = userData.orgs
    .filter(isOrgRoleOwnerOrAdmin)
    .reduce((acc, o) => acc.concat(o.org.id), []);

  const getSpiders = async () => {
    return await sdkInstance.getSpiderSettingsService().getSpiders(selectedTrack.spider.orgId);
  };

  useEffect(() => {
    const setSpiderType = async () => {
      const spiders = await getSpiders();
      const isSpiderX = spiders.find(
        spider =>
          selectedTrack.spider.serialNumber === spider.serialNumber && spider.spiderType === 'SX'
      );
      setIsSpiderX(!!isSpiderX);
    };
    setSpiderType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTrack]);

  // Feature flag for altitude agl & ground level options in dropdown
  const enableAltitudeAglGroundLevel = useFeatureFlag(
    'flight-report-altitude-agl-ground-level',
    userOrgIds
  );

  const isXAxisLabelDisabled = useFeatureFlag(
    'flight-report-chart-x-axis-labels-disabled',
    userOrgIds
  );

  useEffect(() => {
    if (enableAltitudeAglGroundLevel) {
      const getTrackMetadata = async () => {
        const sdkResponse = await sdkInstance
          .getFlightDataService()
          .getFlightMetadata([selectedTrack.id]);

        setTrackMetadata(sdkResponse['items'][0]['metadata']);
      };

      getTrackMetadata();
    }
  }, [setTrackMetadata, selectedTrack, enableAltitudeAglGroundLevel, sdkInstance]);

  // The user is an admin / owner in the org owning the track
  const isTrackViewerAdmin = adminUserOrgIds.includes(selectedTrack.orgId);

  // Org owning the track has insights access
  const hasAccessToInsights = useFeatureFlag('insights', [selectedTrack.orgId]);

  const isFlightReportEnhancementsEnabled = useFeatureFlag(
    'flight-report-enhancements',
    userOrgIds
  );
  const flags = {
    useNewFlightDataService: isFlightReportEnhancementsEnabled
  };
  tracks[0] = selectedTrack;
  const [flightStatus] = useFlightStatus(tracks, flags);

  // The 3d replay statuses which the track must be in
  const acceptableReplayStatuses = [FlightStatus.AVAILABLE, FlightStatus.INCOMPLETE];
  const isReplayStatusAcceptable = flightStatus
    ? acceptableReplayStatuses.includes(flightStatus[selectedTrack.id])
    : false;

  const isAHRSAllowed =
    isReplayStatusAcceptable && hasAccessToInsights && isTrackViewerAdmin && isSpiderX;

  return {
    selectedTrack,
    userData,
    isAHRSAllowed,
    enableAltitudeAglGroundLevel,
    trackMetadata,
    isXAxisLabelDisabled
  };
};

export default useFlightReport;
