import React, { useState, useEffect } from 'react';
import { Tabs, Alert } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { TableEventListeners } from 'antd/lib/table';
import {
  NotificationsTable,
  GeofenceNotificationTableRow,
  ExceedenceNotificationTableRow
} from './NotificationsTable';
import { SolidTooltip } from '@spidertracks/components';
import { useFeatureFlag } from '../../../hooks';

const { TabPane } = Tabs;

export interface NotificationsTabsContainerProps {
  geofenceNotificationTableRows?: GeofenceNotificationTableRow[];
  exceedenceNotificationTableRows?: ExceedenceNotificationTableRow[];
  loading: boolean;
  onRow: (
    record: GeofenceNotificationTableRow | ExceedenceNotificationTableRow
  ) => TableEventListeners;
  organisationId: string;
}

const geofenceTableColumns = [
  {
    title: 'PEOPLE',
    dataIndex: 'people',
    key: 'people',
    width: '20%',
    render: (text: any, record: any, index: number) => {
      return (
        <SolidTooltip placement="bottom" title={record.peopleToolTip} trigger="hover">
          {text}
        </SolidTooltip>
      );
    }
  },
  {
    title: 'TYPE',
    dataIndex: 'notificationType',
    width: '10%',
    key: 'notificationType'
  },
  {
    title: 'GEOFENCES',
    dataIndex: 'geofence',
    width: '30%',
    key: 'geofence',
    render: (text: any, record: any, index: number) => {
      return (
        <SolidTooltip placement="bottom" title={record.geofenceToolTip} trigger="hover">
          {text}
        </SolidTooltip>
      );
    }
  },
  {
    title: 'AIRCRAFT',
    dataIndex: 'aircraft',
    width: '30%',
    key: 'aircraft',
    render: (text: any, record: any, index: number) => {
      return (
        <SolidTooltip placement="bottom" title={record.aircraftToolTip} trigger="hover">
          {text}
        </SolidTooltip>
      );
    }
  }
];

const exceedenceTableColumnsCommon = [
  {
    title: 'PEOPLE',
    dataIndex: 'people',
    key: 'people',
    width: '20%',
    render: (text: any, record: any, index: number) => {
      return (
        <SolidTooltip placement="bottom" title={record.peopleToolTip} trigger="hover">
          {text}
        </SolidTooltip>
      );
    }
  },
  {
    title: 'TYPE',
    dataIndex: 'notificationType',
    width: '10%',
    key: 'notificationType'
  },
  {
    title: 'EXCEEDANCES',
    dataIndex: 'exceedence',
    width: '25%',
    key: 'exceedence',
    render: (text: any, record: any, index: number) => {
      return (
        <SolidTooltip placement="bottom" title={record.exceedenceToolTip} trigger="hover">
          {text}
        </SolidTooltip>
      );
    }
  },
  {
    title: 'RULES',
    dataIndex: 'exceedenceRule',
    width: '25%',
    key: 'exceedenceRule',
    render: (text: any, record: any, index: number) => {
      return (
        <SolidTooltip placement="bottom" title={record.exceedenceRuleToolTip} trigger="hover">
          {text}
        </SolidTooltip>
      );
    }
  }
];

export const NotificationsTabsContainer: React.FC<NotificationsTabsContainerProps> = ({
  geofenceNotificationTableRows,
  exceedenceNotificationTableRows,
  loading,
  onRow,
  organisationId
}) => {
  const isExceedanceNotificationsAdminFlagEnabled = useFeatureFlag(
    'notifications-admin-exceedences',
    [organisationId]
  );
  const orgHasInsights = useFeatureFlag('insights', [organisationId]);
  const orgHasInsightsSafety = useFeatureFlag('insights-safety', [organisationId]);
  let exceedenceTableColumns;
  if (orgHasInsightsSafety) {
    exceedenceTableColumns = [
      ...exceedenceTableColumnsCommon,
      {
        title: 'NOTIFY FOR',
        dataIndex: 'severity',
        width: '20%',
        key: 'severity',
        render: (text: any, record: any, index: number) => {
          return (
            <SolidTooltip placement="bottom" title={record.severityToolTip} trigger="hover">
              {text}
            </SolidTooltip>
          );
        }
      }
    ];
  } else {
    exceedenceTableColumns = [...exceedenceTableColumnsCommon];
  }

  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [curretActiveKey, setCurretActiveKey] = useState(
    pathname.slice(pathname.lastIndexOf('/') + 1)
  );

  useEffect(() => {
    history.listen(location => {
      const { pathname } = location;
      setCurretActiveKey(pathname.slice(pathname.lastIndexOf('/') + 1));
    });
  }, []);

  const onTabChange = (activeKey: string): void => {
    const newPath = pathname.slice(0, pathname.lastIndexOf('/')).concat(`/${activeKey}`);
    history.push(newPath);
  };

  return (
    <React.Fragment>
      <Tabs defaultActiveKey="geofences" onChange={onTabChange} activeKey={curretActiveKey}>
        <TabPane tab="Geofences" key="geofences">
          <NotificationsTable
            columns={geofenceTableColumns}
            notificationsData={geofenceNotificationTableRows}
            loading={loading}
            onRow={onRow}
          />
        </TabPane>
        {isExceedanceNotificationsAdminFlagEnabled && (
          <TabPane tab="Exceedances" key="exceedances">
            {!orgHasInsights && (
              <Alert
                message="Warning"
                description="No active Insights subscription"
                type="warning"
                showIcon
              />
            )}
            <NotificationsTable
              columns={exceedenceTableColumns}
              notificationsData={exceedenceNotificationTableRows}
              loading={loading}
              onRow={onRow}
            />
          </TabPane>
        )}
      </Tabs>
    </React.Fragment>
  );
};

export default NotificationsTabsContainer;
